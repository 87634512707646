import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getLeadsContent = createAsyncThunk('/leads/content', async (type) => {
    if (type === 'Leadscapitalcontactform') {
        const response = await axios.get(`https://backend.bestmedicareplansforseniors.com/contacts`, {})
        return response.data;
    }else if (type === 'Leadscapitalappliedrequest') {
        const response = await axios.get(`https://backend.bestmedicareplansforseniors.com/applications`, {})
        return response.data;
    } else {
        const response = await axios.get(`https://backend.bestmedicareplansforseniors.com/${type}`, {})
        return response.data;
    }
})


// export const getLeadsContent = createAsyncThunk('/leads/content', async (type) => {

//     var config = {
//         method: 'get',
//         url: 'https://backend.bestmedicareplansforseniors.com/bestmedicareplansforseniors'
//     };

//     await axios(config)
//         .then(async function (response) {
//             console.log(response.data);
//             return await  response.data;
//         })
//         .catch(function (error) {
//             console.log(error);
//         });

// })

export const leadsSlice = createSlice({
    name: 'leads',
    initialState: {
        isLoading: false,
        leads: []
    },
    reducers: {


        addNewLead: (state, action) => {
            let { newLeadObj } = action.payload
            state.leads = [...state.leads, newLeadObj]
        },

        deleteLead: (state, action) => {
            let { index } = action.payload
            state.leads.splice(index, 1)
        }
    },

    extraReducers: {
        [getLeadsContent.pending]: state => {
            state.isLoading = true
        },
        [getLeadsContent.fulfilled]: (state, action) => {
            console.log(action.payload)
            state.leads = action.payload
            state.isLoading = false
        },
        [getLeadsContent.rejected]: state => {
            state.isLoading = false
        },
    }
})

export const { addNewLead, deleteLead } = leadsSlice.actions

export default leadsSlice.reducer